import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../config';
import { dateFormatter } from '../../shared/formatters';
import { ActionMenuForExport } from '../../shared/displayComponents/actionMenuForExport';
import {
  useQueryConsolidationsByGroupId,
  useQueryFilingGroups,
  useQueryFilingMembers,
} from '../../shared/queries/filingGroups';
import { useMutationUpdateFilingMembers } from '../../shared/mutations/filingGroups';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../shared/store/selectors';
import { useRowEditMode } from '../../shared/editMode';
import toggleAllSelectionsHeaderFactory from '../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { filingGroupIdSelector, setFilingGroupId } from '../store';
import { useQueryTtiCaseId } from '../../shared/queries/eFile';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { infoNotification } from '../../shared/notification/store/actions';
import { connectionIdSelector } from '../../shared/store/webSocketReducer';

import getColumnDefinitions from './filingMembers.columnDefinitions';
import { INCLUDED_FLAG_FIELD, DATE_ACQUIRED_FIELD, DATE_INCORPORATED } from './constants';

const SUCCESS_NOTIFICATION_MESSAGE = 'Success Branches removed from group';

const getUniqueRowId = ({ data: { entityId } }) => entityId;

const exportDataProps = {
  allColumns: true,
  processCellCallback: ({ node, column }) => {
    switch (column.colId) {
      case INCLUDED_FLAG_FIELD:
        return node.data[column.colId] ? 'Y' : 'N';
      case DATE_ACQUIRED_FIELD:
      case DATE_INCORPORATED:
        return dateFormatter(node.data[column.colId], config.AMERICAN_DATE_FORMAT);
      default:
        return node.data[column.colId];
    }
  },
};

const FilingMembers = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const connectionId = useSelector(connectionIdSelector);
  const filingGroupId = useSelector(filingGroupIdSelector);
  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period, jurisdictionId } = globalContext.params;

  const [selectAllIncluded, setSelectAllIncluded] = useState(false);

  const { data: filingGroupsData, isLoading: isLoadingFilingGroupsData } = useQueryFilingGroups({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const { data: filingMembersData, isLoading: isLoadingFilingMembersData } = useQueryFilingMembers({
    params: { taxYear, period, filingGroupId },
    enabled: Boolean(taxYear && period && filingGroupId),
  });

  const {
    data: consolidationsByGroupId,
    isLoading: isLoadingConsolidationsByGroupId,
  } = useQueryConsolidationsByGroupId({
    params: { taxYear, period, filingGroupId },
    enabled: Boolean(taxYear && period && filingGroupId),
  });

  const {
    mutateAsync: updateFilingMembers,
    isLoading: isUpdatingFilingMembers,
  } = useMutationUpdateFilingMembers();

  useEffect(() => {
    if (filingGroupsData?.length && !filingGroupId) {
      dispatch(setFilingGroupId({ filingGroupId: filingGroupsData[0].filingGroupId }));
    }
  }, [dispatch, filingGroupsData, filingGroupId]);

  const doUpdate = useCallback(
    async rowsToUpdate => {
      await updateFilingMembers({
        filingGroupId,
        globalContext,
        rowsToUpdate,
        connectionId,
      });
    },
    [updateFilingMembers, globalContext, filingGroupId],
  );

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        includedFlag: newRow.includedFlag,
        entityId: oldRow.entityId,
        taxYear,
        period,
        filingGroupId,
      }));

      if (rowsToUpdate.filter(({ includedFlag }) => !includedFlag).length) {
        if (consolidationsByGroupId?.consolidationIds.length) {
          dispatch(
            showConfirmModal({
              title: 'Remove Filing Group Members',
              text: `You cannot save your changes because the return definitions for the consolidations ${consolidationsByGroupId?.consolidationIds.join(
                ', ',
              )} do not have a separate filing form value. Create a support ticket to report this issue.`,
              confirmText: null,
              dismissText: 'Ok',
            }),
          );
          return;
        }
        if (consolidationsByGroupId?.result.length) {
          dispatch(
            showConfirmModal({
              title: 'Remove Filing Group Members',
              text: `You are removing members from ${filingGroupId}. For legal entities, SLT will convert their returns from non-filing to filing. For branches, SLT will delete their returns. To delete a legal entity's return, see the Setup Tax Returns screen.`,
              confirmCallback: async () => {
                doUpdate(rowsToUpdate);
              },
            }),
          );
          return;
        }
      }
      doUpdate(rowsToUpdate);
    },
    [dispatch, doUpdate, taxYear, period, filingGroupId, consolidationsByGroupId],
  );

  const rowData = useMemo(() => filingMembersData || [], [filingMembersData]);

  const handleIncludedFlagValues = useCallback(
    ({ gridApi: api, updateGroup }) => {
      const modifiedRows = [];
      api?.forEachNode(({ data }) => {
        if (data.organizationalType === 'B' && data.includedFlag) {
          data.includedFlag = false;
          modifiedRows.push(data);
        }
      });
      if (modifiedRows.length) {
        updateGroup(modifiedRows);
        dispatch(infoNotification(SUCCESS_NOTIFICATION_MESSAGE));
      }
    },
    [dispatch],
  );

  const isLoading =
    isLoadingFilingGroupsData ||
    isLoadingFilingMembersData ||
    isLoadingConsolidationsByGroupId ||
    isUpdatingFilingMembers;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRowForSelectAll,
    updateRow,
    onGridReady,
    gridApi,
    revertChanges,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    onEnter: handleIncludedFlagValues,
  });

  const areAllFilingMembersSelected = clonedRowData
    .filter(({ organizationalType }) => organizationalType !== 'B' && isInEditMode)
    .every(({ includedFlag }) => includedFlag);
  useEffect(() => setSelectAllIncluded(areAllFilingMembersSelected), [areAllFilingMembersSelected]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNode(({ data }) => {
        if (data.organizationalType !== 'B') {
          data.includedFlag = newValue;
          updateRowForSelectAll(data);
        }
      });
      gridApi.refreshCells({ force: true, columns: ['includedFlag'] });
    },
    [updateRowForSelectAll, gridApi],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllIncluded,
      setTogglerState: setSelectAllIncluded,
      isInEditMode,
      handleOverrideAllValues,
    });

    return getColumnDefinitions({
      updateRow,
      isInEditMode,
      ToggleAllSelectionsHeader,
    });
  }, [updateRow, handleOverrideAllValues, selectAllIncluded, isInEditMode]);

  const exportCSV = useCallback(() => gridApi.exportDataAsCsv(exportDataProps), [gridApi]);
  const exportExcelInXLSX = useCallback(
    () =>
      gridApi.exportDataAsExcel({
        ...exportDataProps,
        exportMode: 'xlsx',
      }),
    [gridApi],
  );
  const exportExcelInXML = useCallback(
    () =>
      gridApi.exportDataAsExcel({
        ...exportDataProps,
        exportMode: 'xml',
      }),
    [gridApi],
  );

  useEffect(() => {
    if (!isInEditMode) {
      revertChanges();
    }
  }, [globalContext.params, isInEditMode, revertChanges]);

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">{editModeButtons}</div>
        </div>
      )}
      <ActionMenuForExport
        isExportDisabled={isInEditMode}
        exportCSV={exportCSV}
        exportExcelInXLSX={exportExcelInXLSX}
        exportExcelInXML={exportExcelInXML}
      />
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isLoading}
            singleClickEdit
            columnDefs={columnDefinitions}
            withSearchBar
            onGridReady={onGridReady}
            getRowId={getUniqueRowId}
          />
        </div>
      </div>
    </>
  );
};

FilingMembers.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingMembers;
